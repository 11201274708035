import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import axios from 'axios';
import Cookies from 'js-cookie';
import useTypedSelector from '@/hooks/useTypedSelector';
import useTypedDispatch from '@/hooks/useTypedDispatch';
import { getUserTariff } from '@/store/Tariffs/actions';
import { toggleLoader } from '@/store/Loader';
import { updateAvatar } from '@/store/User';
import { getCoach } from '@/store/Coaches';
import { OrderPayload } from '@/store/Order/types';
import { postOrders } from '@/store/Order';
import UserCoach from '@/components/ui/Account/UserCoach';
import UserAchieve from '@/components/ui/Account/UserAchieve';
import UserTariff from '@/components/ui/Account/UserTariff';
import AccountLayout from '@/components/common/Layout/AccountLayout';
import { InfoButton } from '@/components/ui/InfoSection';

const AccountPage = (): React.ReactElement => {
  const {
    name,
    surname,
    email,
    tariff_id,
    coach_id,
    avatar,
    tariff_time_format,
    tariff_days
  } = useTypedSelector((state) => state.user);
  const { tariff } = useTypedSelector((state) => state.tariffs);
  const { coach } = useTypedSelector((state) => state.coaches);
  const { orderId } = useTypedSelector((state) => state.orders);
  const dispatch = useTypedDispatch();
  const router = useRouter();
  const [file, setFile] = useState<any>(null);
  const userName = name + surname;
  const paymentRef = useRef<any>(null);

  useEffect(() => {
    setTimeout(() => {
      dispatch(toggleLoader(false));
    }, 100);
  }, []);

  //Получение тарифа юсера
  //Запрос на получение конкретного тренера
  useEffect(() => {
    if (tariff_id && coach_id) {
      dispatch(getUserTariff({ tariff_id }));
      dispatch(getCoach({ id: coach_id }));
    }
  }, [tariff_id, coach_id]);

  //Переход на страницу смены тренера
  const handleClickEditCoach = (): void => {
    router.push('/account/changeCoach');
  };

  //Переход на страницу редактирования аккаунта
  const handleClickEditUser = (): void => {
    router.push('/account/edit');
  };

  //Переход на страницу связи с разработчиками
  const handleClickAppeal = (): void => {
    router.push('/account/contacting');
  };

  const handleClickHome = (): void => {
    router.push('/');
  };

  const handleSubmitForm = (e: any): void => {
    e.preventDefault();
    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append('avatar', file);

    dispatch(toggleLoader(true));
    axios
      .post('https://bg.sportadium.ru/api/user/update-avatar', formData, {
        headers: {
          Authorization: `Bearer ${Cookies.get('sportadium_session')}`
        }
      })
      .then(({ data }: any) => {
        dispatch(updateAvatar(data.data.avatar));
      })
      .finally(() => {
        setFile(null);
        dispatch(toggleLoader(false));
      });
  };

  const handleSubmitPayment = () => {
    const { description, amount, email, phone, receipt }: any =
      paymentRef.current;

    if (receipt) {
      if (!email.value && !phone.value)
        return alert('Поле E-mail или Phone не должно быть пустым');

      paymentRef.current.receipt.value = JSON.stringify({
        EmailCompany: 'sportadium@yandex.ru',
        Taxation: 'patent',
        Items: [
          {
            Name: description.value || 'Оплата',
            Price: amount.value + '00',
            Quantity: 1.0,
            Amount: amount.value + '00',
            PaymentMethod: 'full_prepayment',
            PaymentObject: 'service',
            Tax: 'none'
          }
        ]
      });
    }
    window.pay(paymentRef.current);
  };

  const handleClickPayment = () => {
    const data: OrderPayload = {
      couch_id: coach_id,
      tariff_id,
      price: tariff?.price as number
    };

    dispatch(postOrders(data));

    setTimeout(() => {
      handleSubmitPayment();
    }, 500);
  };

  return (
    <AccountPageContainer>
      <AccountPageContentWrapper>
        <User>
          <HomeButton onClick={() => handleClickHome()} />
          <EditButton onClick={() => handleClickEditUser()} />
          <UserPictureWrapper>
            {avatar && <UserPictureImage src={avatar} />}
            <UploadForm onSubmit={handleSubmitForm}>
              <UserPictureUpload
                type={'file'}
                name={'avatar'}
                onChange={(e: any): void => setFile(e.target.files[0])}
                accept={'image/*,.png,.jpg,'}
              />
              {file && (
                <UserSubmitButton type={'submit'}>
                  Загрузить фото
                </UserSubmitButton>
              )}
            </UploadForm>
          </UserPictureWrapper>

          {name && surname && <UserName>{name + ' ' + surname}</UserName>}

          {tariff && (
            <UserPlanWrapper>
              <UserPlanImage src={'/assets/img/logo/planLogo.svg'} />
              <UserPlan>{tariff.name}</UserPlan>
            </UserPlanWrapper>
          )}
        </User>
        {coach && <UserCoach coach={coach} />}
        <UserAchieve />
        {tariff && (
          <UserTariff
            id={tariff.id}
            name={tariff.name}
            image={tariff.image}
            tariffEnding={tariff_time_format}
          />
        )}
        {tariff_days as number <= 7 && (
          <UserTariffDaysWrapper>
            <UserTariffDaysText>
              Действие тарифа заканчивается,
              <br />
              осталось<span>{' ' + tariff_days + ' дней'}</span>
            </UserTariffDaysText>
          </UserTariffDaysWrapper>
        )}
        {orderId && (
          <form
            name="payform-tinkoff"
            id="payform-tinkoff"
            onSubmit={handleSubmitPayment}
            ref={paymentRef}
          >
            <input
              type="hidden"
              name="terminalkey"
              id="terminalkey"
              value="1698761612834"
            />
            <input type="hidden" name="frame" id="frame" value="false" />
            <input type="hidden" name="language" id="language" value="ru" />
            <input type="hidden" name="receipt" id="receipt" value="" />
            <input
              type="hidden"
              name="amount"
              id="amount"
              value={tariff?.price as number}
              required
            />
            <input
              type="hidden"
              name="order"
              id="order"
              value={orderId as number}
            />
            <input
              type="hidden"
              name="description"
              id="description"
              value={tariff?.name as string}
            />
            <input
              type="hidden"
              name="name"
              id="name"
              value={userName as string}
            />
            <input
              type="hidden"
              name="email"
              id="email"
              value={email as string}
            />
            <input type="hidden" name="phone" id="phone" value="+79123456789" />
          </form>
        )}
        <ButtonWrapper>
          <UserBtn onClick={() => handleClickPayment()}>
            <span>{(tariff_days as number) > 0 ? 'Продлить' : 'Оплатить'}</span>
          </UserBtn>
        </ButtonWrapper>

        <UserHelpersList>
          <UserHelpersItem onClick={handleClickEditCoach}>
            Сменить тренера
          </UserHelpersItem>
          <UserHelpersItem onClick={handleClickAppeal}>
            Связь с разработчиками
          </UserHelpersItem>
        </UserHelpersList>
      </AccountPageContentWrapper>
    </AccountPageContainer>
  );
};

export const AccountPageContainer = styled.div`
    width: 100%;
    height: auto;
    min-width: 320px;
    max-width: 390px;
    min-height: 110vh;
    position: relative;

    :before {
        content: '';
        width: 100%;
        height: 100%;
        max-heigth: 277px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background-image: url('/assets/img/background/backgroundTop.png');
        background-repeat: no-repeat;
        z-index: 1;
    }

    :after {
        content: '';
        width: 100%;
        height: 100%;
        max-height: 359px;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        background-image: url('/assets/img/background/backgroundBottom.png');
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;
    }
`;

export const AccountPageContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
    z-index: 5;
    position: relative;
`;

export const User = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    margin: 0 0 40px 0;
    padding: 25px 23px 0 23px;
`;

export const UserPictureWrapper = styled.label`
    display: flex;
    width: 96px;
    height: 96px;
    margin: 0 auto 45px auto;
    border-radius: 50%;
    border: 1px solid #fff;
    background: rgba(255, 255, 255, 0.1);
    color: white;
    position: relative;
`;

export const UserPictureImage = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
`;

export const UploadForm = styled.form`
    position: fixed;
`;

export const UserPictureUpload = styled.input`
    display: block;
    width: 94px;
    height: 96px;
    border-radius: 50%;
    opacity: 0;
    cursor: pointer;
    z-index: 5;
`;

export const UserSubmitButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 11px;
    font-weight: 400;
    background: rgba(0, 0, 0, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    backdrop-filter: blur(30px);
    position: absolute;
    top: -1px;
    left: 0;
`;

export const UserName = styled.h2`
    display: block;
    width: 100%;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    text-align: center;
    margin: 0 0 18px 0;
`;

export const UserPlanWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const UserPlan = styled.span`
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.32px;
    position: relative;
`;

export const UserPlanImage = styled.img`
    display: block;
    width: 22px;
    height: 22px;
    margin: 0 10px 0 0;
    object-fit: cover;
`;

const UserTariffDaysWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 0 0 40px 0;
    padding: 0 23px;
`;

const UserTariffDaysText = styled.p`
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.28px;
    padding: 0 0 0 30px;
    position: relative;

    span {
        color: #13ffee;
    }

    &:before {
        content: '!';
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        font-size: 22px;
        font-weight: 400;
        color: #13ffee;
        border: 1px solid #13ffee;
        border-radius: 50%;
        position: absolute;
        top: 5px;
        left: -5px;
    }

    @media (min-width: 390px) {
        font-size: 14px;
    }
`;

const UserBtn = styled(InfoButton)``;

export const UserHelpersList = styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin: 0 0 47px 0;
    padding: 0 23px 10px 23px;
`;

export const UserHelpersItem = styled.li`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    margin: 0 0 5px 0;
    cursor: pointer;

    :last-child {
        margin: 0;
    }
`;

const EditButton = styled.a`
    display: block;
    width: 20px;
    height: 24px;
    background-image: url('/assets/img/logo/editLogo.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    color: red;
    cursor: pointer;
    position: absolute;
    top: 48px;
    right: 24px;
    opacity: 1;
`;

export const ButtonWrapper = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 40px 0;
`;

export const HomeButton = styled(EditButton)`
    background-image: url('/assets/img/logo/logo.png');
    top: 48px;
    left: 34px;
`;

AccountPage.getLayout = (page: React.ReactElement) => {
  return <AccountLayout renderFooter={true}>{page}</AccountLayout>;
};

export default AccountPage;
