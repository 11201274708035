import React from 'react';
import styled from 'styled-components';
import { UserTariffAccount } from '@/store/Tariffs/types';

const UserTariff: React.FC<UserTariffAccount> = ({
  id,
  name,
  image,
  tariffEnding,
}): React.ReactElement => {
  return (
    <UserTariffWrapper id={id as string}>
      <UserTariffTitle>Текущий тариф</UserTariffTitle>
      <UserTariffInfoWrapper>
        {image && <UserTariffInfoName img={image}>{name}</UserTariffInfoName>}
        <UserTariffInfoDate>до {tariffEnding}</UserTariffInfoDate>
      </UserTariffInfoWrapper>
    </UserTariffWrapper>
  );
};

const UserTariffWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 0 40px 0;
  padding: 0 13px;
    
    @media (min-width: 390px) {
        padding: 0 23px;
    }
`;

const UserTariffTitle = styled.h3`
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    margin: 0 0 16px 0;
`;

const UserTariffInfoWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    @media (min-width: 390px) {
        flex-wrap: nowrap;
    }
`;

const UserTariffInfoName = styled.p<{ img: string }>`
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.8px;
    white-space: nowrap;
    padding: 0 0 0 44px;
    margin: 0 10px 10px 0;
    position: relative;

    &:before {
        content: '';
        display: block;
        width: 50px;
        height: 50px;
        background-image: ${({ img }) => `url(${img})`};
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: absolute;
        top: -12px;
        left: -10px;
    }

    @media (min-width: 390px) {
        margin: 0 6px 0 0;
        padding-left: 36px;
    }
`;

const UserTariffInfoDate = styled.span`
    display: block;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: right;
    letter-spacing: 0.05em;
    white-space: nowrap;
    color: #00f7e9;
    padding: 0 0 0 43px;
    
    @media (min-width: 390px) {
        padding: 0;
    }
`;

export default UserTariff;
