import styled from 'styled-components';

const TitleH2 = styled.h2`
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.32px;
    text-transform: uppercase;
    text-align: center;
    margin: 0 auto 35px auto;

    @media (min-width: 390px) {
        font-size: 20px;
    }
`;

export default TitleH2;
