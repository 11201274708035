import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import TitleH2 from '@/components/ui/Title';
import InfoSectionProps, { InfoSectionItems } from '@/interfaces/InfoSection';

const InfoSection: React.FC<InfoSectionProps> = ({
                                                   title,
                                                   image,
                                                   imageStyle,
                                                   btnText,
                                                   moreTextInfo,
                                                   visible,
                                                   stylesItems,
                                                   items,
                                                   link,
                                                   auth
                                                 }): React.ReactElement => {
  const router = useRouter();
  const [status, setStatus] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);

  useEffect(() => {
    if (auth) {
      setStatus(auth);
    }
  }, [auth]);

  const renderInfoItems = (items: InfoSectionItems[]): React.ReactElement[] => {
    return items.map(
      ({ id, text }: InfoSectionItems): React.ReactElement => (
        <InfoListItem key={id}>
          <InfoItemText>
            {id + '. ' + text}
          </InfoItemText>
        </InfoListItem>
      )
    );
  };

  const handleClickButton = (e: any, link: string) => {
    e.preventDefault();

    router.push(link);
  };

  const handleClickMoreButton = (): void => {
    active ? setActive(false) : setActive(true);
  };

  const textHtmlFunc = (text: string) => {
    return {
      __html: text
    };
  };

  return (
    <InfoWrapper>
      <InfoImageWrapper margin={stylesItems.imageStyle.margin}>
        <InfoImage
          src={`/assets/img/infoSection/${image}`}
          width={imageStyle?.width}
          height={imageStyle?.height}
        />
      </InfoImageWrapper>
      <TitleH2>{title}</TitleH2>
      <InfoList margin={stylesItems.listStyle.margin}>
        {renderInfoItems(items)}
      </InfoList>
      {!status && (
        <InfoButtonWrapper>
          <SubButtonText dangerouslySetInnerHTML={textHtmlFunc(btnText as string)} />
          {visible && (
            <ShowButton onClick={() => handleClickMoreButton()}>{active ? 'Свернуть' : 'Развернуть'}</ShowButton>
          )}
          {active && <SubButtonMoreText dangerouslySetInnerHTML={textHtmlFunc(moreTextInfo as string)} />}
          <InfoButton
            margin={stylesItems.buttonStyle.margin}
            onClick={(e) => handleClickButton(e, link)}
          >
            <span>Регистрация</span>
          </InfoButton>
        </InfoButtonWrapper>
      )}
    </InfoWrapper>
  );
};

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 0 93px 0;
`;

const InfoImageWrapper = styled.div<{ margin: string }>`
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    background-image: url('/vector.svg');
    background-repeat: no-repeat;
    background-position-y: 81px;
    margin-bottom: ${({ margin }) => margin || '0'};

    :after {
        content: '';
        width: 204px;
        height: 37px;
        position: absolute;
        top: 275px;
        right: 65px;
        background: radial-gradient(
                50% 50% at 50% 50%,
                rgba(255, 255, 255, 0.12) 0%,
                rgba(255, 255, 255, 0) 100%
        );
    }

    z-index: 100;
`;

const InfoImage = styled.img<{ width: string; height: string }>`
    display: block;
    width: ${({ width }) => width};
    height: ${({ height }) => height};
`;

const InfoList = styled.ul<{ margin: string }>`
    display: flex;
    flex-direction: column;
    padding: 0 23px;
    margin-bottom: ${({ margin }) => margin || '0'};
`;

export const InfoListItem = styled.li`
    display: flex;
    justify-content: center;
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 24px -1px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    margin: 0 0 16px 0;
    padding: 13px 0 13px 13px;

    &:last-child {
        margin: 0;
    }
`;

export const InfoItemText = styled.p`
    font-size: 13px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.02em;
`;

export const InfoButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const InfoButton = styled.a<{ margin?: string }>`
    width: 100%;
    height: auto;
    min-width: 320px;
    max-width: 390px;
    min-height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-bottom: ${({ margin }) => margin || '0'};
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0px 0px 24px -1px rgba(0, 0, 0, 0.2);
    border-radius: 27px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:before {
        content: '';
        width: 400px;
        height: 400px;
        background-image: conic-gradient(
                transparent, transparent, transparent, #7353f2
        );
        border-radius: 8px;
        position: absolute;
        animation: animate 6s linear infinite;
    }

    :after {
        display: block;
        content: '';
        width: 400px;
        height: 400px;
        background-image: conic-gradient(
                transparent, transparent, transparent, #53f9fd
        );
        border-radius: 8px;
        position: absolute;
        animation: animate 6s linear infinite;
        animation-delay: -9s;
    }

    span {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 17px;
        font-weight: 600;
        color: #fff;
        background: #020028;
        border-radius: 27px;
        position: absolute;
        inset: 3px;
        z-index: 1;

        @media (min-width: 390px) {
            font-size: 19px;
        }
    }


    @keyframes animate {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

export const SubButtonText = styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 0 0 10px 0;
    padding: 0 23px;

    span {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.28px;
        color: #13ffee;
        margin: 0 0 0 5px;
    }
`;

export const ShowButton = styled.span`
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    letter-spacing: 0.28px;
    color: #13ffee;
    margin: 0 0 20px 0;
`;

const SubButtonMoreText = styled(SubButtonText)``;

export default InfoSection;
