import React, { ReactElement, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { ThemeProvider } from 'styled-components';
import useTypedSelector from '@/hooks/useTypedSelector';
import useTypedDispatch from '@/hooks/useTypedDispatch';
import { getUser, lastUpdate } from '@/store/User/actions';
import LayoutProps from '@/interfaces/Layout';
import AccountFooter from '@/components/common/Footer/AccountFooter';
import Content from '@/components/common/Content';
import Loader from '@/components/common/Loader';
import THEME from '@/styles/theme';
import GlobalStyle from '@/styles/global';

const AccountLayout: React.FC<LayoutProps> = ({ children, renderFooter }) => {
  const { loader } = useTypedSelector((state) => state.loader);
  const { online } = useTypedSelector((state) => state.user);
  const router = useRouter();
  const dispatch = useTypedDispatch();
  const cookiesSession = Cookies.get('sportadium_session');

  useEffect(() => {
    dispatch(getUser());
  }, []);

  useEffect(() => {
    if (cookiesSession) {
      if (!online) {
        dispatch(lastUpdate({ status: true }));
      }
    } else if (!cookiesSession) {
      router.push('/');
    }
  }, [cookiesSession]);

  const renderLoader = (): ReactElement => <Loader />;

  return (
    <ThemeProvider theme={THEME['dark']}>
      <GlobalStyle />
      <Content>
        {children}
      </Content>
      {loader && renderLoader()}
      {renderFooter === true && <AccountFooter />}
    </ThemeProvider>
  );
};

export default AccountLayout;
